import React, { Component } from "react";
import MobileMenuCloseIcon from "@assets/svg/svgui/mobile-menu-close-icon.svg";

import SimpleDropdown from "@components/global/dropdowns/simple-dropdown";

class FilterBarItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dropdownOpen: false,
    };
  }

  componentDidMount = () => {
    this.positionTabIndicator();
  };

  componentDidUpdate = () => {
    this.positionTabIndicator();
  };

  componentWillUnmount = () => {
    document.body.classList.remove("mobile-curators-list");
  };

  positionTabIndicator = () => {
    if (Boolean(this.props.selected) && this.props.tabIndicator) {
      setTimeout(() => {
        const indicatorStyle = {
          width: this.domRef.clientWidth + "px",
          transform: "translateX(" + this.domRef.offsetLeft + "px)",
        };

        this.props.tabIndicator.style.width = indicatorStyle.width;
        this.props.tabIndicator.style.transform = indicatorStyle.transform;
      }, 0);
    }
  };

  onMouseEnter = () => {
    if (
      this.props.browser.greaterThan.mobile &&
      this.props.options &&
      this.props.options.length > 0
    ) {
      this.setState(
        Object.assign({}, this.state, {
          dropdownOpen: true,
        })
      );
    }
  };

  onMouseLeave = () => {
    if (
      this.props.browser.greaterThan.mobile &&
      this.props.options &&
      this.props.options.length > 0
    ) {
      this.setState(
        Object.assign({}, this.state, {
          dropdownOpen: false,
        })
      );
    }
  };

  onClick = () => {
    if (this.props.options && this.props.options.length > 0) {
      if (!this.state.dropdownOpen) {
        if (this.props.browser.lessThan.medium) {
          document.body.classList.add("mobile-curators-list");
        }
        this.setState(
          Object.assign({}, this.state, {
            dropdownOpen: true,
          })
        );
      }
    } else {
      this.props.setSort({
        type: "sort",
        value: this.props.value,
      });
    }
  };

  closeDropdown = () => {
    if (this.props.browser.lessThan.medium) {
      document.body.classList.remove("mobile-curators-list");
    }
    this.setState(
      Object.assign({}, this.state, {
        dropdownOpen: false,
      })
    );
  };

  getClassNames = () => {
    let classes = "events-sort-bar__filter-item";
    if (this.props.selected) {
      classes = "events-sort-bar__filter-item--selected";
    }

    if (this.props.options && this.props.options.length > 0) {
      classes = "events-sort-bar__filter-item__has-dropdown";
      if (this.props.selected) {
        classes = "events-sort-bar__filter-item__has-dropdown--selected";
      }

      if (this.state.dropdownOpen) {
        classes += "--open";
      }
    }

    return classes;
  };

  render() {
    const { value, options } = this.props;

    return (
      <li
        ref={(ref) => {
          this.domRef = ref;
        }}
        onMouseEnter={() => this.onMouseEnter()}
        onMouseLeave={() => this.onMouseLeave()}
        onClick={(e) => this.onClick(e)}
        className={this.getClassNames()}
      >
        {this.props.label}
        <div className="events-sort-bar__filter-item__dropdown-container">
          {this.state.dropdownOpen &&
            this.props.browser &&
            this.props.browser.lessThan.medium && (
              <div className="container__events-curators-close-bar">
                <div
                  className="SVGInline mobile-menu-filter__close"
                  onClick={() => this.closeDropdown()}
                >
                  <MobileMenuCloseIcon
                    className="SVGInline-svg mobile-menu-filter__close-svg"
                    style={{ width: 17, height: 17 }}
                  />
                </div>
                <span className="events-sort-close-bar__caption">
                  {"Curators"}
                </span>
              </div>
            )}
          {this.state.dropdownOpen && (
            <SimpleDropdown type={value} items={options} />
          )}
        </div>
      </li>
    );
  }
}

export default FilterBarItem;
