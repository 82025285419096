import React, { Component } from "react";
import FilterBarItem from "./filter-bar-item";

export default class AccessFilterBar extends Component {
  renderFilterItems = () => {
    return this.props.filters.map((filter, i) => (
      <FilterBarItem
        key={"sb-item-" + filter.label}
        tabIndicator={this.tabIndicatorRef}
        setSort={this.props.updateFilter}
        browser={this.props.browser}
        {...filter}
      />
    ));
  };

  render() {
    return (
      <div className="container container__events--sort-bar container__fullwidth">
        <div className="row row--no-margin-bottom">
          <div className="col s12 col--no-margin-bottom">
            <div className="events-sort-bar clearfix">
              <ul className="events-sort-bar__filter-list" role="tablist">
                <div
                  className="events-sort-bar__indicator-wrapper"
                  ref={(ref) => {
                    this.tabIndicatorRef = ref;
                  }}
                >
                  <div className="events-sort-bar__indicator"></div>
                </div>
                {this.renderFilterItems()}
              </ul>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
