import React, { Component } from "react";
import { Link } from "gatsby";
import { connect } from "react-redux";

import EventsGridModule from "@components/modules/common/events-grid-module";
import AccessFilterBar from "@containers/access/access-filter-bar";

import { GA } from "@constants/config";
import { configureHeader } from "@actions/ui";
import { setAccessFilter } from "@actions/update-access-filter";
import { accessListFilters } from "@selectors/events";
import {
  getFilters,
  getActiveLocations,
  currentLocation,
} from "@selectors/filters";

import { getAllCategoryFilters } from "@utils/filter-utils";
import SadFace from "@assets/image/sad-face.png";
import AccessTempHeader from "@assets/image/accesstempheader.png";
import { isWindowDefined } from "../assets/third-party/blast";

class Access extends Component {
  componentDidMount = () => {
    if (isWindowDefined) {
      window.scrollTo && window.scrollTo(0, this.props.currentScrollOffset);
      window.fbq && window.fbq("track", "PageView");
      window.ga && window.ga("send", "pageview", GA.PAGES.events);
    }
    this.props.configureHeader({
      headerVisibilityLocked: true,
      headerTheme: "white",
    });
  };

  handleFilterChange = (filter) => {
    this.props.setAccessFilter(filter.value);
  };

  renderEventGridModules = (content = []) => {
    const eventGrids = [
      <EventsGridModule
        key={"eventgrid-0"}
        events={content}
        categoryFilters={getAllCategoryFilters(this.props.filters)}
      />,
    ];
    return eventGrids;
  };

  render() {
    const { subFilters, browser, regions } = this.props;
    const subFilter = subFilters.find((filter) => filter.selected).value;
    const region = regions.find((region) => region.selected).value;
    const content = (this?.props?.events || []).filter((event) => {
      return (
        event?.metadata?.rn_event_metadata?.accessSubGroup === subFilter &&
        event.region === region
      );
    });
    return (
      <div className="access-container">
        <div className="access-container__hero">
          <img src={AccessTempHeader} alt="" />
        </div>
        <div className="container">
          <div className="row">
            <div className="col s12 m10 m-center l9 l-center">
              <div className="heading heading--primary">
                <span className="heading__divider"></span>
                <p className="heading__copy">
                  ACCESS members choose 4 events from the{" "}
                  <strong className="text-orange">ACCESS Events</strong> section
                  & 2 events from{" "}
                  <strong className="text-orange">ACCESS Reserve</strong>{" "}
                  section each month.
                  <br />
                  <br />
                  Text your ACCESS Concierge to request entry to any events
                  listed here - whether it's this weekend, next month, or
                  beyond.
                  <br />
                  <br />
                  Your ACCESS Concierge is available from Noon to 6pm, Monday
                  through Saturday.
                  <br />
                  <br />
                  ACCESS passes are reserved on a first-come, first-served
                  basis, so check the page often & get your requests in!
                  <br />
                  <br />
                  Not yet a member? &nbsp;
                  <Link to={"/getaccess"}>
                    <strong className="text-orange">
                      Get ACCESS to these events here.
                    </strong>
                  </Link>
                </p>
              </div>
            </div>
          </div>
          <AccessFilterBar
            updateFilter={this.handleFilterChange}
            filters={subFilters}
            browser={browser}
          />
          {!content || content.length <= 0 ? (
            <div className="row">
              <div className="col s12">
                <div className="no-events__content">
                  <div className="no-events__graphic">
                    <img src={SadFace} alt="" />
                  </div>
                  <div className="no-events__headline">{"No Results?"}</div>
                </div>
              </div>
            </div>
          ) : (
            this.renderEventGridModules(content)
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    currentRegion: currentLocation(state),
    regions: getActiveLocations(state),
    filters: getFilters(state),
    subFilters: accessListFilters(state),
    browser: state.browser,
    platform: state.platform,
    listViewType: state.ui.listViewType,
    events: state.events.data,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    configureHeader: (val) => {
      dispatch(configureHeader(val));
    },
    setAccessFilter: (filter) => {
      dispatch(setAccessFilter(filter));
    },
  };
};

const AccessRedux = connect(mapStateToProps, mapDispatchToProps)(Access);

export default AccessRedux;
